require('../../../__utility/js/jquery.easing.min')(jQuery);
import 'slick-carousel';
var IndexInit = function(){
  var _initialize = false;
  var $window = $(window);
  var $html = $("html");
  var $nav = $("#nav_top");
  var $navCP = $(".nav-sp");
  var $topNavBtn = $(".top #nav_btn");
  var $news = $("#news");

  global.isSP = false;
  var Resize = function(){
    // console.log("Resize");
    if($window.width() > 750){
      isSP = false;
    }else{
      isSP = true;
    }
  };

  var $topContent = $("#top_content");
  if($topContent.length > 0){
    var $visulaContainer = $(".kv--visual");
    var BackgroundOpacity = function(){
      var windowScrollTop = $window.scrollTop() + window.innerHeight;
      var targetContentTop = $topContent.offset().top + (window.innerHeight / 3) - windowScrollTop;
      targetContentTop = (targetContentTop * -1) / (window.innerHeight);

      var _opacity = 1 - targetContentTop + .65;
      if(_opacity < .2){
        _opacity = .2;
      }else if(_opacity > 1){
        _opacity = 1;
      }
      $visulaContainer.css({opacity: _opacity});

    };
    $window.on("scroll", BackgroundOpacity);
    BackgroundOpacity();

    var $topVisual = $("#top_visual");
    var SPNavAppear = function(){
      if($window.scrollTop() > $topVisual.css("top").replace("px", "") | 0){
        if(!$topNavBtn.hasClass("nav-btn-appear")){
          $topNavBtn.addClass("nav-btn-appear");
        }
      }else{
        if($topNavBtn.hasClass("nav-btn-appear")){
          $topNavBtn.removeClass("nav-btn-appear");
        }
      }
    };
    $window.on("scroll", SPNavAppear);
    SPNavAppear();

    var NavPosSet = function(){
      if(isSP){
        $nav
          .removeClass("nav-open")
          .removeClass("nav-appear")
          .removeClass("nav-disappear");
      }else{
        if($window.scrollTop() > $news.offset().top){
          if(!$nav.hasClass("nav-open")){
            $nav.addClass("nav-open").delay(50).queue(function(){
              $nav
                .addClass("nav-appear")
                .dequeue();
            });
          }
        }else{
          if($nav.hasClass("nav-appear")){
            $nav.addClass("nav-disappear").delay(50).queue(function(){
              $nav
                .removeClass("nav-open")
                .removeClass("nav-appear")
                .removeClass("nav-disappear")
                .dequeue();
            });
          }
        }
      }
    };

    $window.on("scroll", NavPosSet);
    NavPosSet();
  }

  var More = function($container){
    var _max;
    if($window.width() > 750){
      _max = $container.data("pc-max") | 0;
    }else{
      _max = $container.data("sp-max") | 0;
    }

    var _$content = $container.find(".content");
    var _$box = $container.find(".box");
    var _$more = $container.find(".more");
    var _$moreWrap = $container.find(".more-wrap");
    var _$moreContent = $container.find(".more-content");
    var _$moreButton = $container.find(".more--btn");

    if(_max === 0){
      _max = _$box.length;
    }

    var _MoveElement = function(){
      if(_$box.length > _max){
        _$content.css({marginBottom: 0});
        _$more.css({display: ""});
        _$box.each(function(i){
          if(i > _max - 1){
            $(this).appendTo(_$moreContent);
          }
        });
      }else{
        _$more.css({display: "none"});
      }
      _isInitialed = true;
    };

    var _MoreOpenClose = function(){
      _$moreWrap.stop().slideToggle(300, function(){
        if(_$moreWrap.css("display") === "none"){
          _$more.removeClass("more--open");
        }else{
          _$more.addClass("more--open");
        }
      });
    };
    _$moreButton.on("click", _MoreOpenClose);

    var _isInitialed = false;
    var _Resize = function(){
      if($window.width() > 750){
      }else{
      }
    };
    $window.on("resize", _Resize);

    _MoveElement();
  };

  More($("#news"));
  More($("#program_tour"));
  More($("#program_workshop"));
  More($("#program_other"));
  More($("#exhibitor_factory"));
  More($("#exhibitor_other"));

  var hash = window.location.hash;
  var ScrollHashPoint = function(hash){
    var __targetHash = hash.replace("_scroll", "");
    var __$target = $(__targetHash);
    var __headerHeight;

    if($window.width() > 750){
      __headerHeight = 80;
      if(__targetHash === "#program"){
        __headerHeight = 180;
      }
    }else{
      __headerHeight = 0;
      if(__targetHash === "#news" || __targetHash === "#program"){
        __headerHeight = 40;
      }
    }
    var __targetOffsetTop = __$target.offset().top - __headerHeight;
    $("html, body").stop().animate({scrollTop: __targetOffsetTop}, 600, "easeOutExpo", function(){
    });
  };
  if(hash !== ""){
    ScrollHashPoint(hash);
  }

  var ScrollToTarget = function(event){
    var __$target = $(event.currentTarget);
    var __headerHeight;
    var __targetHash = __$target.attr("href");
    if(__targetHash === "" || __targetHash.indexOf("#") !== 0){
      return false;
    }
    if($window.width() > 750){
      __headerHeight = 70;
      if(__targetHash === "#program"){
        __headerHeight = 180;
      }
    }else{
      __headerHeight = 0;
      if(__targetHash === "#news" || __targetHash === "#program"){
        __headerHeight = 40;
      }
    }
    var __targetOffsetTop = $(__targetHash).offset().top - __headerHeight;

    $navCP
      .removeClass("nav-open")
      .removeClass("nav-appear")
      .removeClass("nav-disappear");
    $html.removeClass("is-modal");

    $("html, body").stop().animate({scrollTop: __targetOffsetTop}, 600, "easeOutExpo");

    return false;
  };
  $(".anchor").on("click", ScrollToTarget);


  $window.on("resize", Resize);
  Resize();
};

window.addEventListener('load', IndexInit);

var DetailInit = function(){
  var $window = $(window);
  var $html = $("html");
  var $navTop = $("#detail_nav");
  var $nav = $("#nav");
  var $navBtn = $("#nav_btn");
  var $slider = $("#visual_content");
  var $SPTags = $("#sp_tags");

  var NavOpen = function(){
    if(!$nav.hasClass("nav-open")){
      $nav.css({display: "flex"}).delay(20).queue(function(){
        $nav.addClass("nav-open").dequeue();
      });
      $navBtn.addClass("nav-btn-open");
      $html.addClass("is-modal");
    }else{
      $nav.removeClass("nav-open").delay(300).queue(function(){
        $nav.dequeue();
      });
      $navBtn.removeClass("nav-btn-open");
      $html.removeClass("is-modal");
    }
  };

  var DetailNavPosSet = function(){
    if(isSP){
      $navTop
        .removeClass("hide-nav-open")
        .removeClass("hide-nav-appear")
        .removeClass("hide-nav-disappear");
    }else{
      if($slider.length > 0){

        if($window.scrollTop() > $slider.offset().top){
          if(!$navTop.hasClass("hide-nav-open")){
            $navTop.addClass("hide-nav-open").delay(50).queue(function(){
              $navTop
                .addClass("hide-nav-appear")
                .dequeue();
            });
          }
        }else{
          if($navTop.hasClass("hide-nav-appear")){
            $navTop.addClass("hide-nav-disappear").delay(50).queue(function(){
              $navTop
                .removeClass("hide-nav-open")
                .removeClass("hide-nav-appear")
                .removeClass("hide-nav-disappear")
                .dequeue();
            });
          }
        }
      }

    }
  };

  $window.on("scroll", DetailNavPosSet);
  DetailNavPosSet();

  $navBtn.on("click", NavOpen);

  var $bolt = $("#bolt");
  var currentScrollPos = 0;
  var currentBoldRotate = 180;
  var NavRoll = function(event){
    if(isSP){
      var _scrollTop = $window.scrollTop();
      if(_scrollTop > currentScrollPos){
        currentBoldRotate++;
      }else{
        currentBoldRotate--;
      }
      $bolt.css({
        "transform": "rotate(" + (_scrollTop * .5) + "deg)",
        "-webkit-transform": "rotate(" + (_scrollTop * .5) + "deg)",
      });

      currentScrollPos = _scrollTop;
    }
  };
  $window.on("scroll", NavRoll);


  var slickOption = {
    arrows: true,
    appendArrows: $(".visual-arrows"),
    prevArrow: '<div class="slide-arrow prev-arrow"><img src="/assets/images/2021/arrow_slider.svg"></div>',
    nextArrow: '<div class="slide-arrow next-arrow"><img src="/assets/images/2021/arrow_slider.svg"></div>',
    dots: false,
    slidesToShow: 3,
    infinite: false,
    autoplaySpeed: 3000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ]
  };
  var currentSlideNumber = -1;
  $slider.slick(slickOption);
  /*$slider.on("beforeChange", function(event, slick, currentSlide, nextSlide){
    //console.log($slider.slick("slickGetOption", "autoplay"));
    //console.log(currentSlide);
  });
  $slider.on("afterChange", function(event, slick, currentSlide){
    //console.log($slider.slick("slickGetOption", "autoplay"));
    console.log(currentSlide);
    if(currentSlideNumber !== currentSlide){
      currentSlideNumber = currentSlide;
    }else{
      console.log("おなじやん");
      $slider.slick("slickSetOption", {
        autoplay: true,
      });
    }
  });*/

  var DetailResize = function(){
    if($window.width() > 750){
      $SPTags.css({width: ""});
      $navTop
        .removeClass("hide-nav-open")
        .removeClass("hide-nav-appear")
        .removeClass("hide-nav-disappear");
    }else{
      var _spansWidth = 0;
      $SPTags.find("a").each(function(){
        _spansWidth += $(this).outerWidth() + $(this).css("margin-right").replace("px", "") * 1;
      });
      $SPTags.css({width: _spansWidth});
      //console.log(_spansWidth);
    }
  };
  $window.on("resize", DetailResize);
  DetailResize();
};

window.addEventListener('load', DetailInit);